import { useState } from "react";
import { data } from "../../data";

const categories = ['All', 'Appetizers', 'Salad', 'Pasta', 'Pizza', 'Chicken', 'Desserts'];

const Menu = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredFoods, setFilteredFoods] = useState(data);

  const filterFoods = (category) => {
    setSelectedCategory(category);
    setFilteredFoods(category === 'All' ? data : data.filter(item => item.category === category));
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-20 text-white" id="menu">
      <h1 className="text-4xl font-bold text-center text-white mb-10">Menu</h1>
      <div className="flex flex-wrap justify-center gap-3 mb-6">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => filterFoods(category)}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${selectedCategory === category ? 'bg-red-500 text-red-400' : 'text-white bg-transparent border border-red-500 hover:bg-red-500 hover:text-black'} transition-colors duration-300`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredFoods.map((food, index) => (
          <div
            key={index}
            className="border border-gray-700 rounded-lg overflow-hidden shadow-lg transition-shadow hover:shadow-2xl flex flex-col justify-between"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={food.image}
                alt={food.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/300x200?text=No+Image";
                }}
              />
            </div>
            <div className="p-4 bg-gray-900 flex-grow">
              <h3 className="text-lg font-semibold">{food.name}</h3>
              <p className="text-red-400">{food.price}</p>
              <p className="text-gray-500 text-sm mt-1">{food.note || "Delicious!"}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
