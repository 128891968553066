import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiCircleRemove } from "react-icons/ci";
import { motion } from "framer-motion";

import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const overlayVariants = {
    hidden: { opacity: 0, x: "-100vw" },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "-100vw" },
  };

  return (
    <nav className="app__bg app__navbar">
      <motion.div
        className="app__navbar-logo"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <a
          href="/"
          rel="noopener noreferrer"
        >
          <img
            src={images.kissofrome}
            alt="logo"
          />
        </a>
      </motion.div>

      <ul className="app__navbar-links">
        <motion.li
          className="p__opensans"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="#home"
            onClick={() => setToggleMenu(false)}
          >
            Home
          </a>
        </motion.li>
        <motion.li
          className="p__opensans"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="#team"
            onClick={() => setToggleMenu(false)}
          >
            Meet The Team
          </a>
        </motion.li>
        <motion.li
          className="p__opensans"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="#menu"
            onClick={() => setToggleMenu(false)}
          >
            Menu
          </a>
        </motion.li>
        <motion.li
          className="p__opensans"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="#faq"
            onClick={() => setToggleMenu(false)}
          >
            Frequently Asked Questions
          </a>
        </motion.li>
        <motion.li
          className="p__opensans"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="#information"
            onClick={() => setToggleMenu(false)}
          >
            Information
          </a>
        </motion.li>
      </ul>

      <motion.div
        className="app__navbar-order"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <a href="/">
          <img
            src={images.doordash}
            alt="doordash logo"
            className="app__navbar-order-logo"
          />
          <span>DoorDash</span>
        </a>
        <div />
        <a href="/">
          <img
            src={images.grubhub}
            alt="grubhub logo"
            className="app__navbar-order-logo"
          />
          <span>Grubhub</span>
        </a>
      </motion.div>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <motion.div
            className="app__navbar-smallscreen_overlay flex__center"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <CiCircleRemove
              fontSize={27}
              color="#ffffff"
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a
                  href="#home"
                  onClick={() => setToggleMenu(false)}
                >
                  Home
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#team"
                  onClick={() => setToggleMenu(false)}
                >
                  Meet the Team
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#menu"
                  onClick={() => setToggleMenu(false)}
                >
                  Menu
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#faq"
                  onClick={() => setToggleMenu(false)}
                >
                  Frequently Asked Questions
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#information"
                  onClick={() => setToggleMenu(false)}
                >
                  Information
                </a>
              </li>
            </ul>

            <div className="app__navbar-smallscreen_order">
              <a href="/">
                <img
                  src={images.doordash}
                  alt="doordash logo"
                  className="app__navbar-order-logo"
                />
                <span>DoorDash</span>
              </a>
              <div />
              <a href="/">
                <img
                  src={images.grubhub}
                  alt="grubhub logo"
                  className="app__navbar-order-logo"
                />
                <span>Grubhub</span>
              </a>
            </div>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
