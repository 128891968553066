import React, { useState } from "react";
import { motion } from "framer-motion";
import "./FAQ.css";

const FAQ = () => {
  const faqs = [
    {
      question: "Does Kiss of Rome provide gift cards?",
      answer:
        "Yes, we offer gift cards that can be purchased in-store or online at our website, kissofrome.com. They make great gifts for birthdays, anniversaries, or any special occasion. Gift cards are available in various denominations and have no expiration date.",
    },
    {
      question: "Can I order food for delivery or takeout from Kiss of Rome?",
      answer:
        "Yes, we offer both takeout and delivery options. You can order directly through popular delivery apps like DoorDash or Uber Eats. For takeout, call us at 888-888-8888, and we’ll have your order ready for pickup.",
    },
    {
      question: "Is there a dress code at Kiss of Rome?",
      answer:
        "Our dress code is casual or dress-up! You pick, whether you are coming straight form work or enjoying a special evening out, everyone is welcome at Kiss of Rome.",
    },
    {
      question:
        "What is the most popular pasta dish served at Kiss of Rome and what allergens are present in it?",
      answer:
        "Kiss of Rome allows for reservations up to 24 hours prior to seating. Please call our location at 888-888-8888 to let us know your name, phone number, and number of people in your party.",
    },
    {
      question: "How many courses are offered at Kiss of Rome?",
      answer:
        "We offer appetizers, a variety of entree categories, and desserts to give you a full dining experience. Our entrees include salads, pasta, pizza, chicken, steak, and seafood dishes. Each entree has the option to add one or two sides as well.",
    },
    {
      question: "What are the busiest hours at the Kiss of Rome?",
      answer: "Our busiest hours are around dinner time from 5:00 - 6:30 pm.",
    },
    {
      question: "Do you have any vegetarian options?",
      answer: "Yes we offer a multitude of salads as well as many different pasta dishes that can be made vegetarian at the request of the customer.",
    },
    {
      question: "Is Kiss of Rome open on holidays?",
      answer: "No, we like to be able to give all of our wonderful staff some time off on the holidays and are therefore not open.",
    },
    {
      question: "How often is the Kiss of Rome restaurant cleaned?",
      answer: "Our restaurant is fully cleaned daily with appliance deep cleans happening weekly. ",
    },
    {
      question: " How long are typical wait times?",
      answer: "Typically, we can sit in a normal-sized group instantly, but on busy nights such as Friday or large groups, it’s possible to have a wait of up to an hour long. ",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      id="faq"
      className="faq-container"
    >
      <h1 className="faq-title">FAQs</h1>
      <div className="faq-section">
        <h2 className="faq-category">Frequently asked questions</h2>
        <ul className="faq-list">
          {faqs.map((faq, index) => (
            <li
              key={index}
              className="faq-item"
            >
              <div
                className="faq-question"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <motion.span
                  className="faq-icon"
                  initial={{ rotate: 0 }}
                  animate={{ rotate: activeIndex === index ? 45 : 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {activeIndex === index ? "-" : "+"}
                </motion.span>
              </div>
              {activeIndex === index && (
                <motion.div
                  className="faq-answer"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {faq.answer}
                </motion.div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
