import React from "react";
import { motion } from "framer-motion";
import { SubHeading } from "../../components";

const teamMembers = [
  {
    name: "Mia Kushner",
    position: "Owner",
    photo: "https://i.ibb.co/Bt95FhC/pfp-2.png",
  },
  {
    name: "Joseph Carmosino",
    position: "Store Manager",
    photo:
      "https://media.licdn.com/dms/image/v2/D5603AQHYdSOqtctX0A/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1678593040946?e=1737590400&v=beta&t=v_QoCpbSoJ_94OkZY4IymyYKmIhW8SIm3diSc0bq7kI",
  },
  {
    name: "Sebastien Lach",
    position: "Chef",
    photo: "https://i.ibb.co/XjknG5c/IMG-4083.jpg",
  },
  {
    name: "Hieu Ngo",
    position: "Chef",
    photo: "https://claws.rit.edu/photos/images/placeholder.jpg",
  },
  {
    name: "Liam Murphy",
    position: "Waiter",
    photo:
      "https://i.ibb.co/8gs85cY/ugcs3-v3-avatar-Fiuq9g-En-S5-W9-Tk5y-Mxt-Q-LIAM-WIN-20170919-230433.jpg",
  },
];
const Team = () => {
  return (
    <motion.div
      className="app__bg app__wrapper section__padding"
      id="team"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div
        className="app__wrapper_info"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <SubHeading title="Our Team" />
        <motion.h1
          className="headtext__cormorant team__heading"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Meet the Kiss of Rome Team!
        </motion.h1>
        <div className="team__content">
          {teamMembers.map((member, index) => (
            <motion.div
              className="team__member-row"
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 + index * 0.1, duration: 0.8 }}
            >
              <img
                src={member.photo}
                alt={member.name}
                className="team__member-photo"
              />
              <div>
                <h3 className="team__member-position">{member.position}</h3>
                <p className="team__member-name">{member.name}</p>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="team__future-plans"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.8 }}
        >
          <p>
            Our Kiss of Rome team may be small, but we’ve got big plans! Because
            of the wonderful support we’ve received from our community, we have
            plans to grow our family and open two new locations in Rochester.
            Once those locations are fully staffed and operating smoothly, we’d
            like to extend our reach further to include locations in Buffalo and
            Syracuse. We’re also looking for more chefs who can help us expand
            our menu, so if you’re interested in applying for a job now or at
            one of our new planned locations, call us at{" "}
            <a
              href="tel:888-888-8888"
              className="team__contact"
            >
              888-888-8888
            </a>{" "}
            or ask our hostess for an application!
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Team;
