import bg from "../assets/bg.png";
import spoon from "../assets/spoon.svg";
import kissofrome from "../assets/kissofrome.png";
import doordash from "../assets/doordash_logo.png";
import grubhub from "../assets/grubhub-logo.png";

const images = {
  bg,
  spoon,
  kissofrome,
  doordash,
  grubhub,
};

export default images;
