export const data = [
  {
    id: 1,
    name: "Mozzarella Sticks",
    category: "Appetizers",
    image:
      "https://beplantwell.com/wp-content/uploads/2023/01/IMG_8395-540x720.jpg",
    price: "$6.99",
    note: "",
  },
  {
    id: 2,
    name: "Caprese Salad",
    category: "Appetizers",
    image:
      "https://downshiftology.com/wp-content/uploads/2019/07/How-to-Make-Caprese-Salad-1.jpg",
    price: "$7.99",
    note: "",
  },
  {
    id: 3,
    name: "Bruschetta",
    category: "Appetizers",
    image:
      "https://cdn.apartmenttherapy.info/image/upload/f_jpg,q_auto:eco,c_fill,g_auto,w_1500,ar_1:1/k%2FPhoto%2FRecipes%2F2023-07-bruschetta%2FBruschetta_10000jpg",
    price: "$7.99",
    note: "",
  },
  {
    id: 4,
    name: "Caesar Salad",
    category: "Salad",
    image:
      "https://itsavegworldafterall.com/wp-content/uploads/2023/04/Avocado-Caesar-Salad-FI.jpg",
    price: "$9.99",
    note: "",
  },
  {
    id: 5,
    name: "Wedge Salad",
    category: "Salad",
    image:
      "https://pinchandswirl.com/wp-content/uploads/2022/01/Wedge-Salad-sq.jpg",
    price: "$10.99",
    note: "",
  },
  {
    id: 6,
    name: "House Salad",
    category: "Salad",
    image:
      "https://mycasualpantry.com/wp-content/uploads/2023/02/Garden-Salad-1200x1200-1-500x375.jpg",
    price: "$10.99",
    note: "",
  },
  {
    id: 7,
    name: "Italian Salad",
    category: "Salad",
    image:
      "https://www.foodandwine.com/thmb/IuZPWAXBp4YaT9hn1YLHhuijT3k=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/FAW-recipes-big-italian-salad-hero-83e6ea846722478f8feb1eea33158b00.jpg",
    price: "$11.99",
    note: "",
  },
  {
    id: 8,
    name: "Spaghetti and Meatballs",
    category: "Pasta",
    image:
      "https://pinchofyum.com/wp-content/uploads/2013/10/spaghetti-and-meatballs-6-copy-copy.jpg",
    price: "$22.99",
    note: "",
  },
  {
    id: 9,
    name: "Lasagna",
    category: "Pasta",
    image:
      "https://www.southernliving.com/thmb/iI2Pd7BIh0MFuQ79WIEtgli9Ji4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Extra_Easy_Lasagna_006_4x3-41b0a478514c43e9baed0659bd362625.jpg",
    price: "$23.99",
    note: "",
  },
  {
    id: 10,
    name: "Gnocchi",
    category: "Pasta",
    image:
      "https://juliasalbum.com/wp-content/uploads/2023/02/gnocchi-with-tomato-sauce-and-sausage-4.jpg",
    price: "$26.99",
    note: "",
  },
  {
    id: 11,
    name: "Penne alla Vodka",
    category: "Pasta",
    image:
      "https://www.cookingclassy.com/wp-content/uploads/2020/01/penne-alla-vodka-10.jpg",
    price: "$23.99",
    note: "",
  },
  {
    id: 12,
    name: "Ravioli",
    category: "Pasta",
    image:
      "https://cdn11.bigcommerce.com/s-cjh14ahqln/product_images/uploaded_images/cheese-ravioli-2-web.jpg",
    price: "$24.99",
    note: "",
  },
  {
    id: 13,
    name: "Tortellini",
    category: "Pasta",
    image:
      "https://togetherasfamily.com/wp-content/uploads/2023/05/pesto-tortellini-vertical-2.jpg",
    price: "$24.99",
    note: "",
  },
  {
    id: 14,
    name: "Classic Italian Pizza",
    category: "Pizza",
    image:
      "https://d3s8tbcesxr4jm.cloudfront.net/recipe-images/v0/traditional-italian-pizza-with-basil_large.jpg",
    price: "$15.99",
    note: "",
  },
  {
    id: 15,
    name: "Margherita Pizza",
    category: "Pizza",
    image:
      "https://ohsweetbasil.com/wp-content/uploads/how-to-make-authentic-margherita-pizza-at-home-recipe-4.jpg",
    price: "$17.99",
    note: "",
  },
  {
    id: 16,
    name: "New York Style Pizza",
    category: "Pizza",
    image:
      "https://tarasmulticulturaltable.com/wp-content/uploads/2017/04/New-York-Style-Pizza-5-of-5-500x375.jpg",
    price: "$15.99",
    note: "",
  },
  {
    id: 17,
    name: "Pizza Napoletana",
    category: "Pizza",
    image:
      "https://www.whats4eats.com/wp-content/uploads/2023/04/breads-pizza-margherita-wikipedia-ElfQrin-4x3-1.jpg",
    price: "$19.99",
    note: "",
  },
  {
    id: 18,
    name: "Sicilian Pizza",
    category: "Pizza",
    image:
      "https://thebigmansworld.com/wp-content/uploads/2023/08/sicilian-pizza-recipe.jpg",
    price: "$18.99",
    note: "",
  },
  {
    id: 19,
    name: "Chicken Parmesan",
    category: "Chicken",
    image:
      "https://www.dontgobaconmyheart.co.uk/wp-content/uploads/2020/08/baked-chicken-parmesan.jpg",
    price: "$19.99",
    note: "",
  },
  {
    id: 20,
    name: "Grilled Tuscan Chicken",
    category: "Chicken",
    image:
      "https://www.marthastewart.com/thmb/iJpMHIT01o5198Or37c_Xhlhk18=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/04edf32_e_vert-43acdfeb854d47f0bf8f9ba0e98b026e.jpg",
    price: "$18.99",
    note: "",
  },
  {
    id: 21,
    name: "Tiramisu",
    category: "Desserts",
    image:
      "https://www.wellseasonedstudio.com/wp-content/uploads/2023/11/Italian-tiramisu-on-a-plate-with-cocoa-powder-and-chocolate-shavings.jpg",
    price: "$6.99",
    note: "",
  },
  {
    id: 22,
    name: "Chocolate Cake",
    category: "Desserts",
    image:
      "https://bakewithzoha.com/wp-content/uploads/2023/01/chocolate-cake-slice-upright-scaled.jpg",
    price: "$6.99",
    note: "",
  },
  {
    id: 23,
    name: "Brownie with Vanilla Ice Cream",
    category: "Desserts",
    image:
      "https://recipe-graphics.grocerywebsite.com/0_GraphicsRecipes/4929_4k.jpg",
    price: "$7.99",
    note: "",
  },
  {
    id: 24,
    name: "Panna Cotta",
    category: "Desserts",
    image:
      "https://www.cookingclassy.com/wp-content/uploads/2021/05/panna-cotta-01.jpg",
    price: "$7.99",
    note: "",
  },
  {
    id: 25,
    name: "Gelato",
    category: "Desserts",
    image:
      "https://www.thedeliciouscrescent.com/wp-content/uploads/2020/10/Mango-Gelato-5-500x375.jpg",
    price: "$5.99",
    note: "",
  },
  {
    id: 26,
    name: "Cannoli",
    category: "Desserts",
    image:
      "https://www.cookingclassy.com/wp-content/uploads/2020/02/cannoli-20.jpg",
    price: "$5.99",
    note: "",
  },
];

const quotes = [
  {
    quote:
      "The ambiance transports you straight to Rome, and the pasta is as authentic as it gets! Truly a gem.",
  },
  {
    quote:
      "Every dish at Kiss of Rome tastes like it's made with love. The flavors are rich and remind me of my time in Italy.",
  },
  {
    quote:
      "Outstanding service! The staff at Kiss of Rome make you feel like family. The Risotto is a must-try!",
  },
  {
    quote:
      "We celebrated our anniversary at Kiss of Rome and it was magical. The tiramisu is the best I've ever had!",
  },
  {
    quote:
      "From the fresh ingredients to the exquisite wine list, everything at Kiss of Rome screams quality.",
  },
  {
    quote:
      "Kiss of Rome is our go-to for date night. The romantic setting and the divine Spaghetti Carbonara never disappoint.",
  },
  {
    quote:
      "If you want a true taste of Italian cuisine, Kiss of Rome is the place to go. Their seafood dishes are out of this world!",
  },
  {
    quote:
      "Never thought I’d find such authentic Italian cuisine outside of Italy. The Lasagna is sublime and a real tribute to traditional recipes.",
  },
];

export function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex].quote;
}
