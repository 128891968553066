import { Information, Footer, Header, Menu, FAQ, Alerts, Team } from "./container";
import { Analytics } from "@vercel/analytics/react";
import { Navbar } from "./components";
import "./App.css";

const App = () => (
  <div>
    <Alerts />
    <Navbar />
    <Header />
    <Team />
    <Menu />
    <FAQ />
    <Information />
    <Footer />
    <Analytics />
  </div>
);

export default App;
