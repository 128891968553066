import React from "react";
import { motion } from "framer-motion";
import { SubHeading } from "../../components";

const Information = () => {
  return (
    <motion.div
      className="app__bg app__wrapper section__padding"
      id="information"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div
        className="app__wrapper_info"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <SubHeading title="Information" />
        <motion.h1
          className="headtext__cormorant"
          style={{ marginBottom: "2rem", color: "#bc1c35" }}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Find Us
        </motion.h1>
        <div className="app__wrapper-content">
          <motion.p
            className="p__opensans"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            We are located at
            <a
              href="https://www.google.com/maps/dir//rit+address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89d14c603a147e55:0xbe7eb31ed5e22c03?ictx=111"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-red-600"
            >
              {" "}
              1 Lomb Memorial Dr, Rochester, NY 14623. 🐯
            </a>
          </motion.p>

          <motion.p
            className="headtext__cormorant"
            style={{ color: "#bc1c35", margin: "2rem 0" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            Hours
          </motion.p>
          <motion.p
            className="p__opensans"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.8 }}
          >
            Monday: Closed ❌ <br />
            Tuesday - Sunday: 2pm - 10pm
          </motion.p>
          <motion.p
            className="text-sm text-gray-500 mt-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9, duration: 0.8 }}
          >
            These hours are subject to change. Please note, we might be at
            capacity.
          </motion.p>
        </div>
        <a
          href="https://www.google.com/maps/dir//Rochester+Institute+of+Technology,+Lomb+Memorial+Drive,+Rochester,+NY/@43.084819,-77.7539354,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d14c603a147e55:0xbe7eb31ed5e22c03!2m2!1d-77.6715353!2d43.0848486?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
        >
          <motion.button
            className="custom__button"
            style={{ marginTop: "2rem" }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            Directions
          </motion.button>
        </a>
      </motion.div>
    </motion.div>
  );
};

export default Information;
