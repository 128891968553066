import { getRandomQuote } from "../../data";
import React from 'react';
import { motion } from 'framer-motion';
import "./Footer.css";

const currentYear = new Date().getFullYear();

const quote = getRandomQuote();

const Footer = () => {
  const footerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } }
  };

  return (
    <div className="app__footer section__padding">
      <motion.div
        className="app__footer-links"
        initial="hidden"
        animate="visible"
        variants={footerVariants}
      >
        <div className="app__footer-links_contact">
          <motion.h1 className="app__footer-headtext">
            Contact Us
          </motion.h1>
          <motion.p className="app__footer-address">
            1 Lomb Memorial Dr, Rochester, NY 14623
          </motion.p>
          <motion.p className="app__footer-number">
            <a href="tel:8888888888" className="app__footer-number-link">
              888-888-8888
            </a>
          </motion.p>
        </div>
        <div className="app__footer-links_quotes">
          <motion.h1 className="app__footer-headtext">
            Customer Quotes
          </motion.h1>
          <motion.p className="app__footer-quote">
            &quot;{quote}&quot;
          </motion.p>
        </div>
      </motion.div>
      <motion.div
        className="app__footer-copyright"
        initial="hidden"
        animate="visible"
        variants={footerVariants}
      >
        <p>
          &copy; {currentYear} Kiss of Rome All Rights Reserved.
        </p>
        <p>
          Developed by <a href="https://www.josephcarmosino.com">Joseph Carmosino</a>
        </p>
      </motion.div>
    </div>
  );
};

export default Footer;
