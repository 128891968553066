import React from "react";
import { motion } from "framer-motion"
import { SubHeading } from "../../components";
import "./Header.css";

const Header = () => {
  return (
    <motion.div
      className="app__header app__wrapper section__padding"
      id="home"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div
        className="app__wrapper_info"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <SubHeading title="Rediscover Timeless Flavors at Kiss of Rome" />
        <motion.h1
          className="app__header-h1"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Welcome to Kiss of Rome
        </motion.h1>
        <motion.p
          className="p__opensans"
          style={{ margin: "2rem 0" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          At Kiss of Rome™, we take pride in celebrating the heart of Italy
          through our assortment of dishes. We want to ensure a pleasurable
          dining experience with something for everyone in the family. We are
          dedicated to providing quality ingredients and cooking to leave you
          with an unforgettable memory.
        </motion.p>
        <motion.button
          type="button"
          className="custom__button"
          onClick={() => {
            const mainDiv = document.getElementById("menu");
            mainDiv.scrollIntoView({ behavior: "smooth" });
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          Explore Menu
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Header;
